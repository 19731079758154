<template>
  <form ref="uploadForm">
    <slot />
  </form>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: () => ({
    files: [],
    dragAndDropCapable: false
  }),

  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    if (this.dragAndDropCapable) {
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop"
      ].forEach(
        function(evt) {
          this.$refs.uploadForm.addEventListener(
            evt,
            function(e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false
          );
        }.bind(this)
      );
      this.$refs.uploadForm.addEventListener(
        "drop",
        function(e) {
          if (e.dataTransfer.files.length) {
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
              let t = e.dataTransfer.files[i];
              this.files.push({
                file: t,
                name: t.name
              });
            }
            this.setFiles(this.files);
            this.$emit("received-file", 0);
          }
        }.bind(this)
      );
    }
  },
  methods: {
    ...mapMutations("upload", ["setFiles"]),
    /* Fonction upload files */
    determineDragAndDropCapable() {
      var div = document.createElement("div");
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    }
  }
};
</script>
