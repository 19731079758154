<template>
  <v-dialog v-model="dialogEdit" persistent max-width="1000px" scrollable>
    <v-card>
      <v-card-title class="primary headline white--text pt-3" primary-title>
        {{ titleName }}
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark small icon @click.native="closeFTArticle">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <Upload @received-file="createFTAVersion">
          <v-form v-model="valid">
            <v-row>
              <v-col cols="12" v-if="isCreate">
                <v-checkbox
                  label="Fiche technique article"
                  v-model="formData.isFta"
                ></v-checkbox>
              </v-col>
              <v-col cols="4" px-1>
                <v-text-field
                  :disabled="!isEditable || userDroit < 4"
                  :required="isCreate"
                  v-model="formData.nom"
                  :rules="[rules.fieldNoEmpty]"
                  :label="$t('form.articleManagement.dialogDetailFT.name')"
                ></v-text-field>
              </v-col>
              <v-col cols="4" px-1>
                <v-text-field
                  :disabled="!isEditable || userDroit < 4"
                  v-model="formData.titre"
                  :label="$t('form.articleManagement.dialogDetailFT.title')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" px-1>
                <v-text-field
                  v-model="formData.famille"
                  :label="$t('form.articleManagement.dialogDetailFT.family')"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="4" px-1 v-if="!formData.isFta">
                <v-autocomplete
                  :disabled="!isCreate || formData.isFta || userDroit < 4"
                  :items="fournisseurs"
                  :rules="[rules.selectNoEmpty]"
                  v-model="formData.fournisseurId"
                  :label="$t('form.articleManagement.dialogDetailFT.provider')"
                  item-text="TEXT"
                  item-value="VALUE"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" px-1>
                <v-text-field
                  :disabled="!isEditable || userDroit < 4"
                  :required="isCreate"
                  v-model="formData.description"
                  :label="
                    $t('form.articleManagement.dialogDetailFT.description')
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="4" px-1>
                <v-menu
                  :disabled="!isEditable || userDroit < 4"
                  :close-on-content-click="false"
                  v-model="menu"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      :disabled="!isEditable || userDroit < 4"
                      v-model="dateModificationFormated"
                      clearable
                      :label="
                        $t('form.articleManagement.dialogDetailFT.updateDate')
                      "
                      prepend-icon="mdi-calendar"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.dateModification"
                    locale="fr"
                    first-day-of-week="1"
                    no-title
                    scrollable
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-checkbox
                  :disabled="!isEditable || userDroit < 4"
                  :label="
                    $t(
                      'form.articleManagement.dialogDetailFT.chkChangeAllergen'
                    )
                  "
                  v-model="formData.modificationAllergene"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  :disabled="!isEditable || userDroit < 4"
                  :label="
                    $t('form.articleManagement.dialogDetailFT.chkChangeCodeEAN')
                  "
                  v-model="formData.modificationCodeEan"
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  :disabled="!isEditable || userDroit < 4"
                  :label="
                    $t(
                      'form.articleManagement.dialogDetailFT.chkChangeLogisticsCondition'
                    )
                  "
                  v-model="formData.modificationConditionsLogistiques"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  :disabled="!isEditable || userDroit < 4"
                  :label="
                    $t(
                      'form.articleManagement.dialogDetailFT.chkChangeIngredient'
                    )
                  "
                  v-model="formData.modificationIngredient"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  :disabled="!isEditable || userDroit < 4"
                  :label="
                    $t(
                      'form.articleManagement.dialogDetailFT.chkChangeNutritionalValues'
                    )
                  "
                  v-model="formData.modificationValeursNutritionnelles"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  :disabled="!isEditable || userDroit < 4"
                  v-model="formData.modificationAutre"
                  :label="
                    $t('form.articleManagement.dialogDetailFT.ChangeOther')
                  "
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>

          <v-data-table
            v-if="!isCreate"
            :loading="loading"
            :headers="headers"
            :options.sync="options"
            :items="listFTA"
            class="elevation-1 mt-2 list-article-detail"
            :footer-props="{
              'rows-per-page-text': $t('linePage')
            }"
            @click:row="getInfoFT"
          >
            <template slot="headerCell" slot-scope="props">
              <v-tooltip bottom>
                <span slot="activator">
                  {{ props.header.text }}
                </span>
                <span>
                  {{ props.header.text }}
                </span>
              </v-tooltip>
            </template>
            <template #[`item.btn`]="{ item }">
              <v-btn
                fab
                small
                icon
                class="blue--text ma-1"
                @click="exporter(item.ficheTechId)"
                :title="$t('button.download')"
              >
                <v-icon>mdi-cloud-download-outline</v-icon>
              </v-btn>
              <v-btn
                fab
                small
                icon
                class="red--text ma-1"
                v-if="userDroit >= 4"
                @click="deleteArticle(item.version)"
                :title="$t('button.delete')"
              >
                <v-icon>mdi-delete-forever-outline</v-icon>
              </v-btn>
            </template>
            <template slot="pageText" slot-scope="props">
              {{
                $t("lineOf", {
                  pageStart: props.pageStart,
                  pageStop: props.pageStop,
                  itemsLength: props.itemsLength
                })
              }}
            </template>
            <template slot="no-data">
              <v-alert :value="true" color="error" icon="mdi-alert-outline">{{
                $t("noData")
              }}</v-alert>
            </template>
          </v-data-table>
        </Upload>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="error" class="d-block" @click.native="closeFTArticle">{{
          $t("button.close")
        }}</v-btn>
        <v-btn
          color="info"
          class="d-block"
          @click.native="setFTArticle"
          :disabled="!isEditable || !valid || userDroit < 4"
          >{{ $t("button.save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { debounce } from "lodash";
import { format } from "date-fns";
import { getURLApi } from "../utils";
import Upload from "@/components/Upload";
import router from "../router";
export default {
  async created() {
    this.loading = true;
    this.rules = {
      fieldNoEmpty: v => !!v || this.$t("rules.fieldNoEmpty"),
      selectNoEmpty: v => !!v || this.$t("rules.selectNoEmpty"),
      dateFormat: v => {
        const pattern = /^(0[1-9]|[12][0-9]|3[01])[\/ \.](0[1-9]|1[012])[\/ \.](19|20)\d\d$/;
        return pattern.test(v) || this.$t("rules.formatDate");
      }
    };
    this.formData.famille = this.famille;
    if (this.isCreate) {
      this.titleName = this.$t(
        "titleVue.articleManagement.dialogDetailFTCreate"
      );
      this.formData.nom = this.fileName;
      this.isEdit = true;
      await this.fetchListFournisseursDispo({
        articleId: this.articleId
      });
    } else {
      this.titleName = this.$t(
        "titleVue.articleManagement.dialogDetailFTChange"
      );
      await this.getFTInfo({
        articleId: this.articleId,
        ficheTechId: this.ficheTechId,
        fournisseurId: this.fournisseurId,
        isFta: this.isFta
      });
      await this.fetchListFTA({
        articleId: this.articleId,
        fournisseurId: this.fournisseurId,
        isFta: this.isFta
      })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      this.setInitFormData();
    }
    await this.getUserInfo().then(red => {
      this.userDroit = this.userInfo["droit"];
    });
  },

  props: {
    isCreate: { type: Boolean, default: false },
    hasFta: { type: Boolean, default: false },
    isFta: { type: Boolean, default: false },
    fileName: { type: String, default: "" },
    famille: { type: String, default: "" },
    dialog: { type: Boolean, required: true },
    articleId: { type: Number, required: true },
    fournisseurId: { type: Number, required: true },
    ficheTechId: { type: Number, required: true }
  },

  data: () => ({
    userDroit: 0,
    titleName: "",
    valid: false,
    isEdit: false,
    hasChange: false,
    loading: true,
    formDefault: {},
    formData: {
      nom: "",
      titre: "",
      famille: undefined,
      fournisseurId: undefined,
      description: "",
      dateModification: "",
      modificationAllergene: false,
      modificationCodeEan: false,
      modificationConditionsLogistiques: false,
      modificationIngredient: false,
      modificationValeursNutritionnelles: false,
      modificationAutre: "",
      version: 0,
      isFta: false
    },
    menu: false,
    rules: {},
    isEditable: true,
    options: {
      sortBy: ["version"],
      sortDesc: [true]
    },
    headers: [
      {
        text: router.app.$t("headers.articleManagement.dialogDetailFT.file"),
        align: "left",
        value: "fileName"
      },
      {
        text: router.app.$t(
          "headers.articleManagement.dialogDetailFT.provider"
        ),
        align: "left",
        value: "fournisseur"
      },
      {
        text: router.app.$t("headers.articleManagement.dialogDetailFT.version"),
        align: "center",
        value: "version"
      },
      {
        text: router.app.$t("headers.articleManagement.dialogDetailFT.user"),
        align: "left",
        value: "userAjout"
      },
      {
        text: router.app.$t(
          "headers.articleManagement.dialogDetailFT.creationDate"
        ),
        align: "center",
        value: "dateAjout"
      },
      {
        text: "Action",
        value: "btn",
        align: "center",
        sortable: false,
        width: "200px"
      }
    ]
  }),

  computed: {
    ...mapState("articleManagement", [
      "fournisseurFTA",
      "listAttribut",
      "listFTA",
      "listFournisseursDispo"
    ]),
    ...mapState("upload", ["files"]),
    ...mapState("user", ["userInfo"]),
    fournisseurs() {
      if (this.isCreate) return this.listFournisseursDispo;
      return this.listAttribut.FOURNISSEUR;
    },
    dialogEdit: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      }
    },
    dateModificationFormated: {
      get() {
        if (!this.formData.dateModification) return "";
        return this.$d(
          this.$root.$newDate(this.formData.dateModification),
          "date"
        );
      },

      set: debounce(function(val) {
        if (this.rules.dateFormat(val) === true) {
          let t = val.split("/");
          t.reverse();
          t = t.join("-");
          this.formData.dateModification = format(
            this.$root.$newDate(t),
            "yyyy-MM-dd"
          );
        } else {
          this.formData.dateModification = "";
        }
      }, 500)
    },
    dataForm() {
      return { ...this.formData };
    }
  },

  methods: {
    ...mapActions("articleManagement", [
      "getFTInfo",
      "fetchListFTA",
      "deleteArticleFT",
      "fetchListFournisseursDispo",
      "setFTA",
      "editFTA",
      "setNewFTAVersion"
    ]),
    ...mapActions("user", ["getUserInfo"]),
    ...mapMutations("upload", ["shiftFiles", "clearFiles"]),

    getInfoFT(item) {
      this.isEditable = !!item.editable;
      this.getFTInfo({
        articleId: this.articleId,
        fournisseurId: this.fournisseurId,
        version: item.version,
        isFta: this.isFta
      }).then(data => {
        this.setInitFormData();
      });
    },
    setInitFormData() {
      if (this.fournisseurFTA) {
        this.formDefault.nom = this.fournisseurFTA.fileName;
        this.formDefault.titre = this.fournisseurFTA.fileTitle;
        this.formDefault.famille = this.famille;
        this.formDefault.fournisseurId = this.fournisseurFTA.fournisseur;
        this.formDefault.description = this.fournisseurFTA.description;
        this.formDefault.dateModification = this.fournisseurFTA.dateModification;
        this.formDefault.version = this.fournisseurFTA.version;
        this.formDefault.modificationAllergene = !!this.fournisseurFTA
          .modificationAllergene;
        this.formDefault.modificationCodeEan = !!this.fournisseurFTA
          .modificationCodeEan;
        this.formDefault.modificationConditionsLogistiques = !!this
          .fournisseurFTA.modificationConditionsLogistiques;
        this.formDefault.modificationIngredient = !!this.fournisseurFTA
          .modificationIngredient;
        this.formDefault.modificationValeursNutritionnelles = !!this
          .fournisseurFTA.modificationValeursNutritionnelles;
        this.formDefault.modificationAutre = this.fournisseurFTA.modificationAutre;
        this.formDefault.isFta = this.isFta;
        this.formData = { ...this.formDefault };
      }
    },
    setFTArticle() {
      if (this.isCreate) {
        this.submitFiles({ articleId: this.articleId, ...this.formData });
      } else {
        this.editFTA({
          articleId: this.articleId,
          ...this.formData,
          ficheTechniqueId: this.ficheTechId
        })
          .then(data => {
            this.dialogEdit = false;
            this.$emit("refresh", true);
          })
          .catch(res => {
            this.$root.$snackBar(this.$t("errors.unknow"), {
              color: "error"
            });
          });
      }
    },
    closeFTArticle() {
      if (this.isCreate || this.hasChange) {
        this.$root
          .$confirm(this.$t("button.delete"), this.$tc("sureFTChange", 1), {
            color: "error"
          })
          .then(confirm => {
            if (confirm) {
              if (this.isCreate) this.clearFiles();
              this.$emit("close");
            }
          });
      } else {
        this.dialogEdit = false;
      }
    },
    exporter(IdFicheTech) {
      window.open(getURLApi() + "getFta.cfm?ftaId=" + IdFicheTech);
    },
    deleteArticle(version) {
      this.$root
        .$confirm(this.$t("button.delete"), this.$tc("sureArticle", 1), {
          color: "error"
        })
        .then(confirm => {
          if (confirm) {
            this.deleteArticleFT({
              articleId: this.articleId,
              fournisseurId: this.formData.fournisseurId,
              isFta: this.isFta,
              version: version
            })
              .then(data => {
                this.$root.$snackBar(this.$t("success.delete"));
                if (this.listFTA.length === 1) {
                  this.$emit("refresh", true);
                } else {
                  this.loading = true;
                  this.fetchListFTA({
                    articleId: this.articleId,
                    fournisseurId: this.formData.fournisseurId,
                    isFta: this.isFta
                  })
                    .then(data => {
                      this.loading = false;
                    })
                    .catch(res => {
                      this.loading = false;
                    });
                  this.getFTInfo({
                    articleId: this.articleId,
                    ficheTechId: this.ficheTechId,
                    fournisseurId: this.fournisseurId,
                    isFta: this.isFta
                  });
                }
              })
              .catch(res => {
                this.$root.$snackBar(this.$t("errors.delete"), {
                  color: "error"
                });
              });
          }
        });
    },
    submitFiles(data) {
      let formData = new FormData();
      formData.append("files", this.files[0].file);
      this.setFTA({ formData, data })
        .then(data => {
          this.shiftFiles();
          this.$root.$snackBar(this.$t("success.upload"));

          this.$emit("created", 1);
        })
        .catch(res => {
          this.shiftFiles();
          this.$root.$snackBar(this.$t("errors.upload" + res), {
            color: "error"
          });
          this.$emit("created", 1);
        });
    },
    createFTAVersion() {
      if (this.userDroit >= 4) {
        let formData = new FormData();
        this.formData.nom = this.files[0].name;
        let data = this.formData;
        data.articleId = this.articleId;
        formData.append("files", this.files[0].file);
        this.setNewFTAVersion({ formData, data })
          .then(data => {
            this.loading = true;
            this.shiftFiles();
            this.fetchListFTA({
              articleId: this.articleId,
              fournisseurId: this.fournisseurId,
              isFta: this.isFta
            })
              .then(data => {
                this.loading = false;
              })
              .catch(res => {
                this.loading = false;
              });
            this.$root.$snackBar(this.$t("success.upload"));
          })
          .catch(res => {
            this.shiftFiles();
            this.$root.$snackBar(this.$t("errors.upload"), {
              color: "error"
            });
          });
      } else {
        this.$root.$snackBar(this.$t("errors.law"), {
          color: "error"
        });
      }
    }
  },

  watch: {
    dataForm() {
      this.hasChange = !(
        JSON.stringify(this.formData) === JSON.stringify(this.formDefault)
      );
    }
  },

  components: { Upload }
};
</script>

<style scoped>
.list-article-detail >>> .v-datatable th:last-child,
.min-width-action {
  min-width: 144px !important;
  max-width: 144px !important;
}
</style>
