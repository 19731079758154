<template>
  <component
    v-model="value"
    v-bind="$attrs"
    :is="component"
    :item-disabled="disabledItem"
    :disabled="disabled"
    :color="color"
    :items="items"
    :label="label"
    :item-value="itemValue"
    :item-text="itemText"
    :multiple="multiple"
    dense
    :menu-props="menuProps"
    small-chips
    class="select"
    ref="select"
    v-on="$listeners"
  >
    <template slot="selection" slot-scope="{ item, index }">
      <v-chip
        v-if="index === 0 && !hideSelection"
        :class="{ 'primary white--text': !item.disabled && !disabled }"
        small
      >
        <span>{{ valeurDisplay }}</span>
      </v-chip>
      <span v-if="index === 1 && !hideSelection" class="primary--text caption">
        (+{{ value.length - 1 }} {{ $t("others") }})
      </span>
    </template>
    <template v-slot:no-data>
      <span>{{ $t("noResult") }}</span>
    </template>
    <v-list-item
      v-if="isSelectAll && multiple"
      slot="prepend-item"
      ripple
      @click="toggle"
    >
      <v-list-item-action>
        <v-icon :color="selected.length > 0 ? 'indigo darken-4' : ''">
          {{ icon }}
        </v-icon>
      </v-list-item-action>
      <v-list-item-title>{{ $t("selectAll") }}</v-list-item-title>
    </v-list-item>
    <v-divider
      v-if="isSelectAll && multiple"
      slot="prepend-item"
      class="mt-2"
    ></v-divider>
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
        <v-list-item-action v-if="multiple">
          <v-checkbox :input-value="active" />
        </v-list-item-action>
        <v-list-item-content v-if="noAdaptContent">
          <v-tooltip bottom v-if="item">
            <template v-slot:activator="{ on }">
              <span v-on="on" class="truncated">{{ item[itemText] }}</span>
            </template>
            <span>{{ item[itemText] }}</span>
          </v-tooltip>
        </v-list-item-content>
        <v-list-item-content v-else>
          {{ item[itemText] }}
        </v-list-item-content>
      </v-list-item>
    </template>
  </component>
</template>

<script>
import VSelect from "vuetify/es5/components/VSelect";
import VAutocomplete from "vuetify/es5/components/VAutocomplete";

export default {
  data() {
    return {
      menuProps: {},
      resizer: null
    };
  },
  props: {
    items: { type: Array, required: true },
    label: { type: String, required: true },
    selected: { required: true },
    itemValue: { type: String, required: true },
    itemText: { type: String, required: true },
    /* select a choix multiple retourne un array */
    multiple: {
      required: false,
      type: Boolean,
      default: false
    },
    color: {
      required: false,
      type: String,
      default: "blue"
    },
    /* affichage de tout selectionner : permet de cocher toutes les cases ou de les decocher*/
    isSelectAll: {
      type: Boolean,
      required: false,
      default: true
    },
    disabled: { type: Boolean, required: false, default: false },
    /* permet de pouvoir filtrer le select (autocompletion) */
    search: { type: Boolean, required: false, default: false },
    /* si tout est selectionné renvoie la valeur ['all'] */
    valueAll: { type: Boolean, required: false, default: false },
    /* Permet de pouvoir adapter la taille de la list d'item en fonction de la taille du select peut importe la taille du contenu*/
    noAdaptContent: { type: Boolean, required: false, default: false }
  },
  mounted: function() {
    if (this.noAdaptContent) {
      this.resizer = this.debounce(this.onResize, 1000);
      window.addEventListener("resize", this.resizer);
      this.$nextTick(function() {
        this.menuProps = { maxWidth: this.$el.clientWidth };
      });

      // this.debounce(this.onResize, 3000, false)
    }
  },
  beforeDestroy() {
    if (this.noAdaptContent) {
      window.removeEventListener("resize", this.resizer);
    }
  },
  computed: {
    component() {
      return this.search ? "v-autocomplete" : "v-select";
    },
    valeurDisplay() {
      let t = this.items.filter(
        r =>
          (typeof this.value == "object" &&
            this.value.findIndex(row => r[this.itemValue] == row) >= 0) ||
          (typeof this.value != "object" && r[this.itemValue] == this.value)
      );
      return t.length > 0 ? t[0][this.itemText] : "";
    },
    value: {
      get() {
        return this.valueAll && this.selected.findIndex(r => r == "all") >= 0
          ? this.items.map(r => r[this.itemValue]).filter(r => r != undefined)
          : this.selected;
      },
      set(val) {
        let valeur = null;
        if (this.valueAll) {
          let l =
            val.length ===
            this.items.map(r => r[this.itemValue]).filter(r => r != undefined)
              .length;
          valeur = l ? ["all"] : val;
        } else valeur = val;
        this.$emit("update:selected", valeur);
      }
    },
    likesAll() {
      return (
        this.value.length ===
        this.items.map(r => r[this.itemValue]).filter(r => r != undefined)
          .length
      );
    },
    likesSome() {
      return this.value.length > 0 && !this.likesAll;
    },
    icon() {
      if (this.likesAll) return "mdi-close-box";
      if (this.likesSome) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    hideSelection() {
      return this.$refs.select ? this.$refs.select.isMenuActive : false;
    }
  },

  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likesAll) {
          this.value = this.items
            .map(r => (r.disabled ? r[this.itemValue] : 0))
            .filter(r => r != 0);
        } else {
          this.value = this.items
            .map(r => r[this.itemValue])
            .filter(r => r != undefined);
        }
      });
    },
    disabledItem(obj) {
      return !!obj.disabled;
    },
    onResize(event) {
      this.menuProps = { maxWidth: this.$el.clientWidth };
    },
    debounce(func, wait, immediate) {
      var timeout;
      return function() {
        var context = this,
          args = arguments;
        var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    }
  },
  components: { VSelect, VAutocomplete }
};
</script>
<style scoped>
.truncated {
  display: block;
  white-space: nowrap; /* forces text to single line */
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
